import React from "react"

import Layout from "src/components/layout"
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';

const Contact = () => (
  <Layout>
    <div>
      <p>To reach me directly, send me an e-mail at <a href="mailto:hello@bchen.me">hello@bchen.me</a>. Or you can use the handy form below.</p>
    </div>
  </Layout>
)

export default Contact
